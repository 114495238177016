import { Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { Router, RouterLink } from '@angular/router';

@Component({
  selector: 'app-page-not-found',
  template: `<div class="mx-48 my-48 text-center">
    <p class="mt-4 text-3xl font-bold tracking-tight text-ica-primary sm:text-[8rem]">404</p>
    <p class="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
      Lo sentimos, no pudimos encontrar la página
    </p>

    <div class="mt-10 flex items-center justify-center gap-x-6">
      <button routerLink="/dashboard" class="ic-btn-third">
        <mat-icon>keyboard_return</mat-icon>
        Ir al Home
      </button>
    </div>
  </div> `,
  standalone: true,
  imports: [RouterLink, MatIconModule],
})
export class PageNotFoundComponent {
  constructor(private router: Router) {}
}
