<app-breadcrums seccion="LOG - AUDITORIA"></app-breadcrums>

<section class="mb-6">
  <div class="mx-auto flex w-full md:w-11/12">
    <div class="relative w-full">
      <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
        <svg
          aria-hidden="true"
          class="h-5 w-5 text-gray-500"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg">
          <path
            fill-rule="evenodd"
            d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
            clip-rule="evenodd"></path>
        </svg>
      </div>
      <input
        type="text"
        class="ic-input-search-table"
        placeholder="Buscar por ID"
        [formControl]="searchFieldCars"
        (keydown.enter)="searchCarsOnSubmit()"
        #inputSerchAuto />
    </div>
    <button type="submit" class="ic-button-search" (click)="searchCarsOnSubmit()">
      <svg
        aria-hidden="true"
        class="-ml-1 mr-2 h-5 w-5"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg">
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
      </svg>
      Buscar
    </button>
  </div>

  <div class="my-4">
    <ngx-json-viewer [json]="itemLog" [expanded]="true"></ngx-json-viewer>
  </div>
</section>
