import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'ic-ring-central',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './ring-central.component.html',
  styleUrls: ['./ring-central.component.scss'],
})
export class RingCentralComponent {
  constructor() {
    const rcs = document.createElement('script');
    rcs.src = 'https://ringcentral.github.io/ringcentral-embeddable/adapter.js';
    const rcs0 = document.getElementsByTagName('script')[0];
    rcs0.parentNode.insertBefore(rcs, rcs0);
  }
}
