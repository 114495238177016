import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@shared/shared.module';
import { CatalogosGeneralesService } from '@core/services/catalogos-generales.service';
import { LogAudit } from '@core/models/responsehttp.model';
import { NgxJsonViewerModule } from 'ngx-json-viewer';

@Component({
  selector: 'app-audit-log',
  standalone: true,
  imports: [CommonModule, SharedModule, ReactiveFormsModule, NgxJsonViewerModule],
  templateUrl: './audit-log.component.html',
  styleUrls: ['./audit-log.component.scss'],
})
export class AuditLogComponent {
  private readonly service = inject(CatalogosGeneralesService);

  itemLog!: LogAudit[];

  searchFieldCars = new FormControl('');

  searchCarsOnSubmit() {
    this.service.getLogAudit(this.searchFieldCars.value).subscribe((data) => {
      if (data.code === 200 && Array.isArray(data.result) && data.result.length > 0) {
        this.itemLog = data.result.map((item) => {
          return { ...item, log: JSON.parse(item.log) };
        });
      }
    });
  }
}
