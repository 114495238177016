<div class="my-40 flex flex-col items-center">
  <!--p-progressSpinner
    [style]="{ width: '50px', height: '50px' }"
    styleClass="custom-spinner"
    strokeWidth="8"
    fill="var(--surface-ground)"
    animationDuration=".5s">
  </p-progressSpinner-->

  <span class="ic-label-subtitle">Cargando archivo.</span>
</div>
