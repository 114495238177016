import { Component, Inject, OnInit, ViewEncapsulation, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { EventMessage, EventType, InteractionStatus, RedirectRequest } from '@azure/msal-browser';
import { SubSink } from 'subsink';
import { filter } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'ic-login',
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  imports: [CommonModule, MatIconModule],
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  private readonly subs = new SubSink();
  private readonly msalBroadcastService = inject(MsalBroadcastService);
  private readonly authService = inject(MsalService);
  private readonly _msalService = inject(MsalService);
  private readonly router = inject(Router);

  constructor(@Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration) {}

  ngOnInit(): void {
    this.subs.add(
      this.msalBroadcastService.inProgress$
        .pipe(filter((status: InteractionStatus) => status === InteractionStatus.None))
        .subscribe(() => {
          if (this.authService.instance.getAllAccounts().length > 0) this.router.navigate(['/dashboard']);
        })
    );
  }

  login() {
    if (this.msalGuardConfig.authRequest)
      this._msalService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
    else this._msalService.loginRedirect();
  }
}
