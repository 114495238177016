import { GraphService } from '@core/services/graph.service';
import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { ProfileType } from '@core/models/utils.model';
import { environment } from 'src/environments/environment';
import { MenuItem } from '@core/models/user-roles.model';
import { SubSink } from 'subsink';
import { StoreLocalStorageAuthService } from '@auth/store-localStorage-auth.service';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { RouterModule } from '@angular/router';
import { Store } from '@ngrx/store';
import { GlobalSelectors } from '@core/stateGlobal';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ic-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
  standalone: true,
  imports: [CommonModule, MatDividerModule, RouterModule, NgOptimizedImage],
})
export class SidebarComponent implements OnInit, OnDestroy {
  private readonly store = inject(Store);
  private readonly storeService = inject(StoreLocalStorageAuthService);
  private readonly subs = new SubSink();

  environment = environment.production;
  dataProfile: ProfileType;
  menu: MenuItem[] = [];

  ngOnInit(): void {
    const userSession$ = this.store.select(GlobalSelectors.selectUserSession);

    this.subs.add(
      userSession$.subscribe(({ profileGraph }) => {
        if (!profileGraph) return;

        this.dataProfile = profileGraph;
      })
    );

    //SPACE PARA LA VERSION 2 DE ROLES
    const resources$ = this.store.select(GlobalSelectors.selectResourcesCRM);

    this.subs.add(
      resources$.subscribe((resources) => {
        if (!resources) return;

        if (resources?.activev2Roles) {
          this.subs.add(
            userSession$.subscribe(({ menuGraph }) => {
              if (!menuGraph) return;
              this.menu = menuGraph;
            })
          );
        } else {
          this.subs.add(
            this.storeService.menu$.subscribe((menu: MenuItem[]) => {
              this.menu = menu;
            })
          );
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
