import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { StoreLocalStorageAuthService } from '@auth/store-localStorage-auth.service';
import { AlertService } from '@core/services/alert.service';

import { catchError, map, of, tap } from 'rxjs';

export const RolUserChildGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);
  const _alertService = inject(AlertService);
  const _storeAuthLocalStorage = inject(StoreLocalStorageAuthService);

  const allowedRol = state.url;

  const scopesUser$ = of(_storeAuthLocalStorage.getScopesUser());

  return scopesUser$.pipe(
    map((scopes) => Boolean(scopes?.some((scope) => allowedRol.includes(scope)))),
    tap(
      (hasRole) =>
        hasRole === false &&
        _alertService
          .confirmAction('', 'No tienes permisos para acceder a esta sección', 'error', false, 'Aceptar')
          .then(() => {
            router.navigate(['/dashboard']);
            return false;
          })
    ),
    catchError(() => {
      router.navigate(['/dashboard']);
      return of(false);
    })
  );
};
