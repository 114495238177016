<ng-container *ngIf="loginDisplay">
  <mat-sidenav-container>
    <mat-sidenav #sidebar mode="side" class="h-full">
      <ic-sidenav></ic-sidenav>
    </mat-sidenav>
    <mat-sidenav-content>
      <mat-toolbar class="ic-header_sidenav">
        <button (click)="sidebar.toggle()">
          <mat-icon class="text-4xl" *ngIf="!sidebar.opened">menu</mat-icon>
          <mat-icon class="text-4xl" *ngIf="sidebar.opened">close</mat-icon>
        </button>

        <div class="flex items-center justify-center space-x-8">
          <button
            type="button"
            class="relative inline-flex h-10 w-10 items-center justify-center overflow-hidden rounded-full bg-ica-backgroung"
            aria-expanded="false"
            type="button"
            (click)="isOpenBody = !isOpenBody"
            cdkOverlayOrigin
            #bodyOverlay="cdkOverlayOrigin">
            <span class="font-extrabold text-ica-textsidebar">{{ profile?.letterAvatar }}</span>
          </button>
        </div>
      </mat-toolbar>

      <!--ic-notificaciones-ws /-->

      <div class="min-h-full">
        <div class="mx-auto my-8" [ngClass]="{ container: isContainer, 'px-8': !isContainer }" *ngIf="loginDisplay">
          <!--This is to avoid reload during acquireTokenSilent() because of hidden iframe -->
          <router-outlet></router-outlet>
        </div>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>

  <ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="bodyOverlay"
    [cdkConnectedOverlayOpen]="isOpenBody"
    (overlayOutsideClick)="isOpenBody = !isOpenBody">
    <div class="z-50 my-4 list-none divide-y divide-gray-100 rounded bg-white text-base shadow">
      <div class="flex items-center justify-between px-4 py-2">
        <span class="block text-sm text-gray-900">{{ profile?.displayName }}</span>
        <button (click)="isOpenBody = !isOpenBody" class="block text-sm text-gray-900">
          <mat-icon>close</mat-icon>
        </button>
      </div>

      <div class="px-4 py-3">
        <span class="block truncate text-sm font-medium text-gray-500">{{ profile?.userPrincipalName }}</span>
      </div>
      <ul class="py-1" aria-labelledby="user-menu-button">
        <li *appShowForRoles="['Administrador - CRM']">
          <button
            class="block w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100"
            routerLink="/ajustes-admin">
            Ajustes
          </button>
        </li>
        <li>
          <button (click)="logout()" class="block w-full px-4 py-2 text-left text-sm text-gray-700 hover:bg-gray-100">
            Cerrar sesión
          </button>
        </li>
      </ul>
    </div>
  </ng-template>

  <ic-ring-central *ngIf="displayRingCentral && isEnvProd" />
</ng-container>
