import { Component, OnInit, inject } from '@angular/core';
import { TransaccionesService } from 'src/app/transacciones/services/transacciones.service';
import { Router, RouterModule } from '@angular/router';
import { ProfileType } from '@core/models/utils.model';
import { TableColumn } from '@shared/components/table-data/tablecolumn.model';
import { Store } from '@ngrx/store';
import { GlobalSelectors } from '@core/stateGlobal';
import { SubSink } from 'subsink';
import { formatDateCustom } from '@shared/utils/utils';
import { CalendarioService } from 'src/app/calendario-v2/services/calendario.service';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared/shared.module';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  standalone: true,
  imports: [CommonModule, RouterModule, SharedModule],
})
export class DashboardComponent implements OnInit {
  private store = inject(Store);
  private _calendarioService = inject(CalendarioService);
  private subs = new SubSink();

  profile!: ProfileType;

  lRegCitas: any;

  seccionActual = 'Dashboard';

  //Tabla citas
  displayedColumns: TableColumn[] = [
    { dataKey: 'descriptionAuto', name: 'Auto', isSortable: true },
    { dataKey: 'dia', name: 'Cita', isSortable: true },
    { dataKey: 'estado', name: 'Status', isSortable: true },
    { dataKey: 'title', name: 'Tipo evento', isSortable: true },
    { dataKey: 'responsable', name: 'Responsable', isSortable: true },
  ];

  constructor(private _transaccionesService: TransaccionesService, private router: Router) {}

  goToDetails(row) {
    this.router.navigate(['/calendariov2']);
  }

  ngOnInit(): void {
    const userSession$ = this.store.select(GlobalSelectors.selectUserSession);

    this.subs.add(
      userSession$.subscribe(({ profileGraph }) => {
        if (!profileGraph) return;

        this.profile = profileGraph;
        this.getDataCitas(this.profile.userPrincipalName);
        this.obtenerTransaccionesEntrega();
      })
    );
  }

  getDataCitas(user: string) {
    const getDate = formatDateCustom(new Date().toDateString(), 'yyyy-MM-dd');

    let body = {
      fechaFinal: getDate,
      fechaInicial: getDate,
      user,
    };
    this._calendarioService.stateEventsToPromise(body).then((result) => {
      if (result.code === 200 && Array.isArray(result.result) && result.result.length > 0) {
        this.lRegCitas = this._calendarioService.getDataSourceCitas(result.result);
      } else {
        this.lRegCitas = [];
      }
    });
  }

  obtenerTransaccionesEntrega() {
    let etapaName = 'entrega';
    this._transaccionesService
      .getTransaccionesEtapas('5', 'date_string', etapaName, '', '')
      .subscribe((data: any) => {});
  }

  /**
   * Muestra contadores por etapa
   * falta actulizar de acuerdo a los ajustes de las etapas
  obtenerStageCounter() {
    this.contadoresEtapa;
    this.loadingContadores = false;
    this._transaccionesService.getCarsByStageCounter().subscribe({
      next: (data: any) => {
        this.contadoresEtapa = data.result;
        this.loadingContadores = true;
      },
      error: () => {
        //  this.mensajeErrorDatos();
        this.loadingContadores = true;
      },
    });
  }*/
}
