<div class="flex h-full w-full flex-col items-center justify-center gap-16">
  <img class="w-60" src="../assets/img/logo-blanco.png" alt="Logo ICaranty" />
  <button type="button" class="ic-btn-primary w-1/4 text-2xl" (click)="login()">
    <mat-icon>login</mat-icon>
    <span class="ml-2"> Iniciar sesión </span>
  </button>
  <div class="flex flex-col items-center">
    <span class="text-lg font-bold text-ica-primary"> Copyright © 2022 Caranty.com </span>
    <span class="text-lg font-bold text-ica-primary"> Todos los derechos reservados </span>
  </div>
</div>
