import { ErrorHandler, Injectable } from '@angular/core';

@Injectable()
export class GlobalErrorHandler extends ErrorHandler {
  constructor() {
    super();
  }

  override handleError(error: Error) {
    console.error('Error CATCH: ', error);
    // Manually log exception => AppInsights Azure
  }
}
